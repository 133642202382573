<template>
  <div class="itemBox">
    <!-- <div class="avatar">
      <el-avatar
        shape="square"
        :size="50"
        src="https://polyjetclub-backend.polysmartchain.com/images/normal/866.png"
      ></el-avatar>
    </div> -->
    <avatar-item :addr="it.id" :name="it.nickName"></avatar-item>
    <div class="user">
      <div class="name">{{ it.nickName || "" }}</div>
      <div class="addr">{{ it.address || "" }}</div>
    </div>
    <div class="videoTitle">{{ it.videoTitle || "" }}</div>
    <div class="income">
      <div class="num" v-if="it.direction == 1">+{{ it.amount || "" }}</div>
      <div class="num err" v-if="it.direction == 2">-{{ it.amount || "" }}</div>
      <div class="time">{{ uti.changeTime(it.updateTime) || "" }}</div>
    </div>
  </div>
</template>
<script>
import AvatarItem from "../../components/avatar/avatar-item.vue";
import Store from "../../store";
export default {
  components: { AvatarItem },
  props: {
    it: {
      type: Object,
    },
  },
  data() {
    return {
      store: Store.state,
      uti: Store.uti,
      i: {
        address: "123456", //  用户地址	string
        amount: "123", //	打赏金额	integer
        direction: "1", //	方向 1.收入 2.支出	integer
        id: "456", //	id	integer
        nickName: "张三", //	用户昵称	string
        targetUserId: "456978", //	目标用户id	integer
        updateTime: [2022, 5, 24], //	操作时间	string
        userId: "987", //	用户id	integer
        videoTitle: "今晚打老虎", //	视频标题	string
      },
    };
  },
};
</script>

<style lang="less" scoped>
.itemBox {
  display: flex;
  justify-content: space-between;
  height: (54 / 3.75vw);
  align-items: center;
  // border-bottom: 2px solid #d8d8d8;
  .user {
    margin-left: (16 / 3.75vw);
    // flex: 1;
    .name {
      /* 金牌讲师 */

      height: (23 / 3.75vw);
      font-family: SourceHanSansCN-Regular;
      font-size: (16 / 3.75vw);
      font-weight: normal;
      letter-spacing: 0em;
      color: #333333;
    }
    .addr {
      /* 0Xd4a65s4d56a4sd65sad889a7dsad4as56dasd */

      height: (20 / 3.75vw);
      font-family: SourceHanSansCN-Regular;
      font-size: (14 / 3.75vw);
      font-weight: normal;
      letter-spacing: 0em;
      color: #999999;
    }
  }
  .videoTitle {
    padding: 0 (50 / 3.75vw);
    flex: 1;
    /* 视频标题 */

    height: (26 / 3.75vw);
    font-family: SourceHanSansCN-Bold;
    font-size: (18 / 3.75vw);
    font-weight: bold;
    letter-spacing: 0em;
    color: #333333;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .income {
    text-align: right;
    .num {
      /* +800 */

      height: (23 / 3.75vw);
      font-family: SourceHanSansCN-Regular;
      font-size: (16 / 3.75vw);
      font-weight: normal;
      letter-spacing: 0em;
      color: #0a961d;
    }
    .err {
      color: #ec5853;
    }
    .time {
      /* 2022/05/18 */

      height: (17 / 3.75vw);
      font-family: SourceHanSansCN-Regular;
      font-size: (12 / 3.75vw);
      font-weight: normal;
      letter-spacing: 0em;
      color: #999999;
    }
  }
}
</style>
