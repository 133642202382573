<template>
  <div>
    <div class="ge"></div>
    <div class="itemBoxBox">
      <div class="title">收入详情</div>
      <!-- <div class="link"></div> -->
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="全部" name="first"> </el-tab-pane>
        <el-tab-pane label="收入" name="second"> </el-tab-pane>
        <el-tab-pane label="打赏" name="third"> </el-tab-pane>
      </el-tabs>
      <box-2-item
        :it="item"
        v-for="(item, index) in list"
        :key="index"
      ></box-2-item>
      <div class="not" v-if="list.length == 0">暂无数据</div>
    </div>
  </div>
</template>
<script>
import Store from "../../store";
import box2Item from "./box2-item.vue";
export default {
  components: { box2Item },
  mounted() {
    this.getDate();
  },
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
      activeName: "first",
      act: 0,
      list: [
        // {
        //   address: "123456", //  用户地址	string
        //   amount: "123", //	打赏金额	integer
        //   direction: "1", //	方向 1.收入 2.支出	integer
        //   id: "456", //	id	integer
        //   nickName: "张三", //	用户昵称	string
        //   targetUserId: "456978", //	目标用户id	integer
        //   updateTime: [2022, 5, 24], //	操作时间	string
        //   userId: "987", //	用户id	integer
        //   videoTitle: "今晚打老虎", //	视频标题	string
        // },
      ],
    };
  },
  methods: {
    // 选中了某一项
    handleClick(tab, event) {
      console.log(tab, event);
      this.act = tab.index;
      this.getDate();
    },
    // 打赏列表
    async getDate() {
      if (!this.store.user.userId) {
        return this.$message.warning("请先登录");
      }
      let { data: res } = await this.$http.post(`/videoReward/record/list`, {
        pageNum: 1,
        pageSize: 99999,
        type: this.act,
        userId: this.store.user.userId,
      });
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      this.list = res.data.records;
    },
  },
};
</script>

<style lang="less" scoped>
.ge {
  height: 10px;
  width: 100%;
  background-color: #f1f1f1;
  margin-top: 10px;
}
.itemBoxBox {
  margin: (10 / 3.75vw) (18 / 3.75vw);
  padding-bottom: 30vh;
  .title {
    height: (25 / 3.75vw);
    font-family: PingFang SC Bold;
    font-size: (18 / 3.75vw);
    font-weight: normal;
    letter-spacing: 0em;
    color: #333333;
  }
  .link {
    /* 矩形 10 */
    height: 2px;
    background: #d8d8d8;
  }
  .not {
    text-align: center;
    color: #999;
  }
}
</style>
