<template>
  <div class="viewBox">
    <box-1></box-1>
    <box-2></box-2>
  </div>
</template>
<script>
import box1 from "./box1.vue";
import Box2 from "./box2.vue";
export default {
  components: { box1, Box2 },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.viewBox {
}
</style>
