<template>
  <div class="itemBox">
    <div class="box">
      <div class="userBox">
        <avatar-item></avatar-item>
        <div class="text">
          <div class="name">{{ store.user.nickName }}</div>
          <div class="addr">{{ uti.shrink(store.user.address, 6) }}</div>
        </div>
      </div>
      <div class="amoutBox">
        <div class="amout">{{ income.totalIncome || 0 }} PSC</div>
        <div class="shou" v-if="false">收获</div>
      </div>
      <div class="zr">
        昨日：
        <span>{{ income.totalPay || 0 }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import AvatarItem from "../../components/avatar/avatar-item.vue";
import Store from "../../store";
export default {
  components: {
    AvatarItem,
  },
  mounted() {
    this.getDat;
  },
  data() {
    return {
      store: Store.state,
      uti: Store.uti,
      income: {
        totalIncome: 0,
        totalPay: 0,
      },
    };
  },
  methods: {
    // 打赏总金额
    async getDate() {
      if (!this.store.user.userId) {
        return this.$message.warning("请先登录");
      }
      let { data: res } = await this.$http.post(
        `/videoReward/statistics/${this.store.user.userId}`
      );
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      console.log(res.data);
      this.income = res.data;
    },
  },
};
</script>

<style lang="less" scoped>
.itemBox {
  .box {
    /* 矩形 6 */
    margin: 0 (18 / 3.75vw);
    // width: 100%;
    height: (170 / 3.75vw);
    border-radius: (10 / 3.75vw);
    background: #ffffff;
    // box-shadow: 0px 4px (10 / 3.75vw) 0px rgba(153, 153, 153, 0.3);
    padding: (10 / 3.75vw) (10 / 3.75vw);
    box-sizing: border-box;
    .userBox {
      display: flex;
      .text {
        padding-left: (9 / 3.75vw);
        flex: 1;
      }
    }
    .amoutBox {
      display: flex;
      justify-content: space-between;
      margin-top: (16 / 3.75vw);
      .amout {
        /* 1,211 PSC */
        height: (43 / 3.75vw);
        font-family: SourceHanSansCN-Bold;
        font-size: (30 / 3.75vw);
        font-weight: bold;
        letter-spacing: 0em;
        color: #ff7d41;
      }
      .shou {
        /* 矩形 22 */

        width: (107 / 3.75vw);
        height: (46 / 3.75vw);
        line-height: (46 / 3.75vw);
        border-radius: (5 / 3.75vw);
        text-align: center;
        background: #ff7d41;
        /* 收获 */

        font-family: SourceHanSansCN-Regular;
        font-size: (14 / 3.75vw);
        font-weight: normal;
        letter-spacing: 0em;
        color: #ffffff;
      }
      .zr {
        /* 昨日： */
        height: (20 / 3.75vw);
        font-family: SourceHanSansCN-Regular;
        font-size: (14 / 3.75vw);
        font-weight: normal;
        letter-spacing: 0em;
        color: #666666;
        span {
          /* +856 */
          height: (23 / 3.75vw);
          font-family: SourceHanSansCN-Regular;
          font-size: (16 / 3.75vw);
          font-weight: normal;
          letter-spacing: 0em;
          color: #0a961d;
        }
      }
    }
  }
}
</style>
